import { Card, Col, Input, Label, ListGroup, Button, Row, ListGroupItem, Form, Spinner } from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useState, useContext, useEffect } from "react"
import { toast } from "react-hot-toast"
import Select from "react-select"
import { InputOption, activeTradeStatus, activeTradeStatusOption, dummyItemOptions, indexNameOption, orderStatusOption } from "../../utils"
import { ArrowLeft } from "react-feather"
import Flatpickr from "react-flatpickr"
import { expiryOption, statusOption } from "../../stockOptions"
import { getInstruments, getOptionsInstruments, getStocktById, getStrById, postAddStock, postAddStr, putStock, putStr } from "../../myApiCenter"
import MainNav from "../MainNav/MainNav"
// import { getAccountAsOption, getAllOrder, getDesignAsOption, getOrderById, postOrder, putOrderById } from "../../myApiCenter"

const AddStr = () => {
   const { id } = useParams()
   const navigate = useNavigate()
   const [loading, setloading] = useState(false)
   const [data, setdata] = useState({
      strikeFactor: { Monday: "OTM", Tuesday: "OTM", Wednesday: "OTM", Thursday: "OTM", Friday: "OTM", Saturday: "OTM", Sunday: "OTM" },
      index: { brokerDetail: { name: "", symboltoken: "", tradingsymbol: "", instrumenttype: "", exchange: "" }, highLine: 0, lowLine: 0 },
      call: {
         brokerDetail: { symboltoken: "", tradingsymbol: "", instrumenttype: "", exchange: "", lotSize: 0, expiry: "" },
         quantity: 0,
         isActive: true,
         ltp: 0,
         marginPoint: 0,
         targetValue: 0,
         triggerValueForBuy: 0,
         stopLossValue: 0,
         orderStatus: "pending",
         isTargetHit: false,
         isStopLossHit: false
      },
      put: {
         brokerDetail: { symboltoken: "", tradingsymbol: "", instrumenttype: "", exchange: "", lotSize: 0, expiry: "" },
         quantity: 0,
         isActive: true,
         ltp: 0,
         marginPoint: 0,
         targetValue: 0,
         triggerValueForBuy: 0,
         stopLossValue: 0,
         orderStatus: "pending",
         isTargetHit: false,
         isStopLossHit: false
      },
      strikeDifference: 0,
      dailyTradeCount: 0,
      targetPoint: 0,
      stopLossPoint: 0,
      activeTradeStatus: "NONE",
      isActiveFromAdmin: true,
      name: "",
      description: "",
      lowExtendPoint: ""
   })
   const [expiryOptions, setexpiryOptions] = useState([])
   const [callOptions, setcallOptions] = useState([])
   const [putOptions, setputOptions] = useState([])

   const getData = async () => {
      try {
         const j = await getStrById(id)
         // console.log(j.data.stock)

         setdata(() => j?.data?.str)
         // console.log("second achived")
      } catch (error) {}
   }
   const getAllData = async () => {
      try {
         await getOptionData()
         await getData()

         await getCallPutOptionData()
      } catch (error) {}
   }
   const getOptionData = async () => {
      // console.log("called")
      try {
         toast.loading("fetching index option")
         const j = await getInstruments()
         console.log(j)
         // console.log("first achived")
         setexpiryOptions(j)
      } catch (error) {
         console.log(error)
      } finally {
         toast.dismiss()
      }
   }
   const getCallPutOptionData = async () => {
      // console.log("called")
      if (!data.name) {
         toast.error("provide valid stretagy name")
      } else if (!data.index?.brokerDetail?.symboltoken) {
         toast.error("please select index first")
      } else {
         try {
            toast.loading("fetching options")
            const j = await getOptionsInstruments(data.name, data.index?.brokerDetail?.symboltoken)
            toast.dismiss()
            toast.success("option feched")
            setputOptions(() => j.putOption)
            setcallOptions(() => j.callOption)
         } catch (error) {
            console.log(error)
         } finally {
         }
      }
   }
   // console.log(files)
   const submitHandler = async (e) => {
      e.preventDefault()
      console.log(data)
      if (data) {
         try {
            setloading(true)
            let j
            if (id) {
               j = await putStr(id, data)
            } else {
               j = await postAddStr(data)
            }
            j && navigate("/str")
         } finally {
            setloading(false)
         }
      } else {
         toast.error("please select generic service")
      }
   }
   useEffect(() => {
      if (id) {
         getAllData()
      } else {
         getOptionData()
      }
      return () => {}
   }, [])
   console.log(data)
   return (
      <>
         <MainNav />
         <Card style={{ height: "fit-content", width: "90vw", margin: "auto", padding: "30px" }}>
            <div className="d-flex align-items-center p-1 ">
               <button style={{ border: "transparent" }} onClick={() => navigate(-1)}>
                  {/* < style={{ color: "gray" }} /> */}
                  <ArrowLeft />
               </button>
               <h1 className="ms-2 datatable-title-head">{id ? "Edit Stock" : "Add Stock"}</h1>
            </div>
            <div className="w-100">
               <Col>
                  <Form className="d-flex justify-content-center gap-2 flex-column w-100" onSubmit={submitHandler}>
                     <Row>
                        {" "}
                        <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                           <p className="h4">Select stretagy Index name :</p>
                           <Select
                              value={data?.name && indexNameOption.filter((option) => option.value === data?.name)[0]}
                              options={indexNameOption}
                              onChange={(e) => setdata({ ...data, name: e.value })}
                              components={{ Option: InputOption }}
                              className="react-select my-filter-select w-50"
                              placeholder="Select from list"
                           />
                        </Col>
                     </Row>
                     <Row>
                        <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                           <p className="h4">
                              Stratagy Detail <span style={{ color: "red" }}>*</span>
                           </p>
                           <Input
                              value={data?.description}
                              required
                              onChange={(e) => setdata({ ...data, description: e.target.value })}
                              type="text"
                              className="w-50"
                              placeholder="Enter stretagy description"
                           />
                        </Col>
                     </Row>
                     <Col className="border" style={{ backgroundColor: "lightgray" }}>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Select Index :</p>
                              <Select
                                 // isMulti
                                 // value={}
                                 // options={dummyItemOptions}
                                 value={
                                    data?.index?.brokerDetail?.tradingsymbol &&
                                    expiryOptions.filter((option) => option.value === data?.index?.brokerDetail?.tradingsymbol)[0]
                                 }
                                 options={expiryOptions}
                                 onChange={(e) => setdata({ ...data, index: { brokerDetail: e } })}
                                 components={{ Option: InputOption }}
                                 className="react-select my-filter-select w-50"
                                 placeholder="Select from list"
                              />
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <span className="h4 me-2 ">Trading Symbol :</span>
                              <span className="h4 me-2 ">{data?.index?.brokerDetail?.tradingsymbol}</span>
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <span className="h4 me-2 ">Symbol Token:</span>
                              <span className="h4 me-2 ">{data?.index?.brokerDetail?.symboltoken}</span>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Hign Line</p>
                              <Input
                                 value={data?.index?.highLine}
                                 required
                                 onChange={(e) => setdata({ ...data, index: { ...data.index, highLine: +e.target.value } })}
                                 type="number"
                                 className="w-50"
                                 placeholder="Enter Low Line"
                              />
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Low Line</p>
                              <Input
                                 value={data?.index?.lowLine}
                                 required
                                 onChange={(e) => setdata({ ...data, index: { ...data.index, lowLine: +e.target.value } })}
                                 type="number"
                                 className="w-50"
                                 placeholder="Enter High Line"
                              />
                           </Col>
                        </Row>
                     </Col>
                     <Col className="border" style={{ backgroundColor: "lawngreen" }}>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Select Call Option(Strike Price) :</p>
                              <Select
                                 // isMulti
                                 // value={}
                                 onFocus={callOptions.length === 0 && getCallPutOptionData}
                                 // options={dummyItemOptions}
                                 value={
                                    data?.call?.brokerDetail?.tradingsymbol &&
                                    callOptions.filter((option) => option.value === data?.call?.brokerDetail?.tradingsymbol)[0]
                                 }
                                 options={callOptions}
                                 onChange={(e) => setdata({ ...data, call: { ...data.call, brokerDetail: e } })}
                                 components={{ Option: InputOption }}
                                 className="react-select my-filter-select w-50"
                                 placeholder="Select from list"
                              />
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <span className="h4 me-2 ">Trading Symbol :</span>
                              <span className="h4 me-2 ">{data?.call?.brokerDetail?.tradingsymbol}</span>
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <span className="h4 me-2 ">Symbol Token:</span>
                              <span className="h4 me-2 ">{data?.call?.brokerDetail?.symboltoken}</span>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <span className="h4 me-2 ">lot Size :</span>
                              <span className="h4 me-2 ">{data?.call?.brokerDetail?.lotsize}</span>
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <span className="h4 me-2 ">expiry : </span>
                              <span className="h4 me-2 ">{data?.call?.brokerDetail?.expiry}</span>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Order Status :</p>
                              <Select
                                 // isMulti
                                 // value={}
                                 options={orderStatusOption}
                                 value={data?.call?.orderStatus && orderStatusOption.filter((option) => option.value === data?.call?.orderStatus)[0]}
                                 // options={expiryOptions}
                                 onChange={(e) => setdata({ ...data, call: { ...data.call, orderStatus: e.value } })}
                                 components={{ Option: InputOption }}
                                 className="react-select my-filter-select w-50"
                                 placeholder="Select from list"
                              />
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4 me-2 ">Active</p>
                              <div className=" form-switch form-check-success">
                                 <Input
                                    type="switch"
                                    id=""
                                    onChange={(e) => setdata({ ...data, call: { ...data.call, isActive: e.target.checked } })}
                                    name="success"
                                    checked={data?.call?.isActive}
                                 />
                              </div>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4 me-2 ">Target Hitted</p>
                              <div className=" form-switch form-check-success">
                                 <Input
                                    type="switch"
                                    id=""
                                    onChange={(e) => setdata({ ...data, call: { ...data.call, isTargetHit: e.target.checked } })}
                                    name="success"
                                    checked={data?.call?.isTargetHit}
                                 />
                              </div>
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4 me-2 ">StopLoss Hitted</p>
                              <div className=" form-switch form-check-success">
                                 <Input
                                    type="switch"
                                    id=""
                                    onChange={(e) => setdata({ ...data, call: { ...data.call, isStopLossHit: e.target.checked } })}
                                    name="success"
                                    checked={data?.call?.isStopLossHit}
                                 />
                              </div>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">margin point</p>
                              <Input
                                 value={data?.call?.marginPoint}
                                 required
                                 onChange={(e) => setdata({ ...data, call: { ...data.call, marginPoint: +e.target.value } })}
                                 type="number"
                                 className="w-50"
                                 placeholder="Enter margin point"
                              />
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">ltp</p>
                              <Input
                                 value={data?.call?.ltp}
                                 required
                                 onChange={(e) => setdata({ ...data, call: { ...data.call, ltp: +e.target.value } })}
                                 type="number"
                                 className="w-50"
                                 placeholder="Enter margin point"
                              />
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Trigger Value</p>
                              <Input
                                 value={data?.call?.triggerValueForBuy}
                                 required
                                 onChange={(e) => setdata({ ...data, call: { ...data.call, triggerValueForBuy: +e.target.value } })}
                                 type="number"
                                 className="w-50"
                                 placeholder="Enter margin point"
                              />
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Quantity(In lots)</p>
                              <Input
                                 value={data?.call?.quantity}
                                 required
                                 onChange={(e) => setdata({ ...data, call: { ...data.call, quantity: +e.target.value } })}
                                 type="number"
                                 className="w-50"
                                 placeholder="Enter margin point"
                              />
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Target value</p>
                              <Input
                                 value={data?.call?.targetValue}
                                 required
                                 onChange={(e) => setdata({ ...data, call: { ...data.call, targetValue: +e.target.value } })}
                                 type="number"
                                 className="w-50"
                                 placeholder="Enter margin point"
                              />
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Stop loss value</p>
                              <Input
                                 value={data?.call?.stopLossValue}
                                 required
                                 onChange={(e) => setdata({ ...data, call: { ...data.call, stopLossValue: +e.target.value } })}
                                 type="number"
                                 className="w-50"
                                 placeholder="Enter margin point"
                              />
                           </Col>
                        </Row>
                     </Col>
                     <Col className="border" style={{ backgroundColor: "lightcoral" }}>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Select PUT Option(Strike Price) :</p>
                              <Select
                                 // isMulti
                                 // value={}
                                 onFocus={callOptions.length === 0 && getCallPutOptionData}
                                 // options={dummyItemOptions}
                                 value={
                                    data?.put?.brokerDetail?.tradingsymbol &&
                                    putOptions.filter((option) => option.value === data?.put?.brokerDetail?.tradingsymbol)[0]
                                 }
                                 options={putOptions}
                                 onChange={(e) => setdata({ ...data, put: { ...data.put, brokerDetail: e } })}
                                 components={{ Option: InputOption }}
                                 className="react-select my-filter-select w-50"
                                 placeholder="Select from list"
                              />
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <span className="h4 me-2 ">Trading Symbol :</span>
                              <span className="h4 me-2 ">{data?.put?.brokerDetail?.tradingsymbol}</span>
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <span className="h4 me-2 ">Symbol Token:</span>
                              <span className="h4 me-2 ">{data?.put?.brokerDetail?.symboltoken}</span>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <span className="h4 me-2 ">lot Size :</span>
                              <span className="h4 me-2 ">{data?.put?.brokerDetail?.lotsize}</span>
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <span className="h4 me-2 ">expiry : </span>
                              <span className="h4 me-2 ">{data?.put?.brokerDetail?.expiry}</span>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Order Status :</p>
                              <Select
                                 // isMulti
                                 // value={}
                                 options={orderStatusOption}
                                 value={data?.put?.orderStatus && orderStatusOption.filter((option) => option.value === data?.put?.orderStatus)[0]}
                                 // options={expiryOptions}
                                 onChange={(e) => setdata({ ...data, put: { ...data.put, orderStatus: e.value } })}
                                 components={{ Option: InputOption }}
                                 className="react-select my-filter-select w-50"
                                 placeholder="Select from list"
                              />
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4 me-2 ">Active</p>
                              <div className=" form-switch form-check-success">
                                 <Input
                                    type="switch"
                                    id=""
                                    onChange={(e) => setdata({ ...data, put: { ...data.put, isActive: e.target.checked } })}
                                    name="success"
                                    checked={data?.put?.isActive}
                                 />
                              </div>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4 me-2 ">Target Hitted</p>
                              <div className=" form-switch form-check-success">
                                 <Input
                                    type="switch"
                                    id=""
                                    onChange={(e) => setdata({ ...data, put: { ...data.put, isTargetHit: e.target.checked } })}
                                    name="success"
                                    checked={data?.put?.isTargetHit}
                                 />
                              </div>
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4 me-2 ">StopLoss Hitted</p>
                              <div className=" form-switch form-check-success">
                                 <Input
                                    type="switch"
                                    id=""
                                    onChange={(e) => setdata({ ...data, put: { ...data?.put, isStopLossHit: e.target.checked } })}
                                    name="success"
                                    checked={data?.put?.isStopLossHit}
                                 />
                              </div>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">margin point</p>
                              <Input
                                 value={data?.put?.marginPoint}
                                 required
                                 onChange={(e) => setdata({ ...data, put: { ...data?.put, marginPoint: +e.target.value } })}
                                 type="number"
                                 className="w-50"
                                 placeholder="Enter margin point"
                              />
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">ltp</p>
                              <Input
                                 value={data?.put?.ltp}
                                 required
                                 onChange={(e) => setdata({ ...data, put: { ...data.put, ltp: +e.target.value } })}
                                 type="number"
                                 className="w-50"
                                 placeholder="Enter margin point"
                              />
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Trigger Value</p>
                              <Input
                                 value={data?.put?.triggerValueForBuy}
                                 required
                                 onChange={(e) => setdata({ ...data, put: { ...data.put, triggerValueForBuy: +e.target.value } })}
                                 type="number"
                                 className="w-50"
                                 placeholder="Enter margin point"
                              />
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Quantity(In Lots)</p>
                              <Input
                                 value={data?.put?.quantity}
                                 required
                                 onChange={(e) => setdata({ ...data, put: { ...data.put, quantity: +e.target.value } })}
                                 type="number"
                                 className="w-50"
                                 placeholder="Enter margin point"
                              />
                           </Col>
                        </Row>
                        <Row>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Target value</p>
                              <Input
                                 value={data?.put?.targetValue}
                                 required
                                 onChange={(e) => setdata({ ...data, put: { ...data?.put, targetValue: +e.target.value } })}
                                 type="number"
                                 className="w-50"
                                 placeholder="Enter margin point"
                              />
                           </Col>
                           <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                              <p className="h4">Stop loss value</p>
                              <Input
                                 value={data?.put?.stopLossValue}
                                 required
                                 onChange={(e) => setdata({ ...data, put: { ...data.put, stopLossValue: +e.target.value } })}
                                 type="number"
                                 className="w-50"
                                 placeholder="Enter margin point"
                              />
                           </Col>
                        </Row>
                     </Col>
                     <Row>
                        {" "}
                        <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                           <p className="h4">Trade Count</p>
                           <Input
                              value={data?.dailyTradeCount}
                              required
                              onChange={(e) => setdata({ ...data, dailyTradeCount: +e.target.value })}
                              type="number"
                              className="w-50"
                              placeholder="Enter margin point"
                           />
                        </Col>
                     </Row>
                     <Row>
                        {" "}
                        <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                           <p className="h4">Strike Price Difference</p>
                           <Input
                              value={data?.strikeDifference}
                              required
                              onChange={(e) => setdata({ ...data, strikeDifference: +e.target.value })}
                              type="number"
                              className="w-50"
                              placeholder="Enter strike price difference ex for nifty = 50"
                           />
                        </Col>
                     </Row>
                     <Row>
                        {" "}
                        <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                           <p className="h4">Target Point</p>
                           <Input
                              value={data?.targetPoint}
                              required
                              onChange={(e) => setdata({ ...data, targetPoint: +e.target.value })}
                              type="number"
                              className="w-50"
                              placeholder="Enter Target Point"
                           />
                        </Col>
                        <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                           <p className="h4">Stop Loss Point</p>
                           <Input
                              value={data?.stopLossPoint}
                              required
                              onChange={(e) => setdata({ ...data, stopLossPoint: +e.target.value })}
                              type="number"
                              className="w-50"
                              placeholder="Enter strike price difference ex for nifty = 50"
                           />
                        </Col>
                     </Row>
                     <Row>
                        <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                           <p className="h4">Active Trade Status :</p>
                           <Select
                              // isMulti
                              // value={}
                              options={activeTradeStatusOption}
                              value={
                                 data?.activeTradeStatus && activeTradeStatusOption.filter((option) => option.value === data?.activeTradeStatus)[0]
                              }
                              // options={expiryOptions}
                              onChange={(e) => setdata({ ...data, activeTradeStatus: e.value })}
                              components={{ Option: InputOption }}
                              className="react-select my-filter-select w-50"
                              placeholder="Select from list"
                           />
                        </Col>
                        <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                           <p className="h4 me-2 ">Stretagy Active</p>
                           <div className=" form-switch form-check-success">
                              <Input
                                 type="switch"
                                 id=""
                                 onChange={(e) => setdata({ ...data, isActiveFromAdmin: e.target.checked })}
                                 name="success"
                                 checked={data?.isActiveFromAdmin}
                              />
                           </div>
                        </Col>
                     </Row>
                     <Row>
                        <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                           <p className="h4 me-2 ">Force Stop For Day</p>
                           <div className=" form-switch form-check-success">
                              <Input
                                 type="switch"
                                 id=""
                                 onChange={(e) => setdata({ ...data, isForceStop: e.target.checked })}
                                 name="success"
                                 checked={data?.isForceStop}
                              />
                           </div>
                        </Col>
                     </Row>

                     <Row>
                        <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                           <p className="h4 me-2 ">Trade Found</p>
                           <div className=" form-switch form-check-success">
                              <Input
                                 type="switch"
                                 id=""
                                 onChange={(e) => setdata({ ...data, isTradeFound: e.target.checked })}
                                 name="success"
                                 checked={data?.isTradeFound}
                              />
                           </div>
                        </Col>
                     </Row>
                     <Row>
                        <Col className="ms-3 mt-2 mb-2 d-flex gap-5 align-items-center">
                           <p className="h4 me-2 ">Trade Running</p>
                           <div className=" form-switch form-check-success">
                              <Input
                                 type="switch"
                                 id=""
                                 onChange={(e) => setdata({ ...data, isTradeRunning: e.target.checked })}
                                 name="success"
                                 checked={data?.isTradeRunning}
                              />
                           </div>
                        </Col>
                     </Row>
                     <Button className="w-25 mt-2 ms-3 my-btn" disabled={loading}>
                        Save {loading && <Spinner size="sm" />}
                     </Button>
                  </Form>
               </Col>
            </div>
         </Card>
      </>
   )
}
export default AddStr
