import { Input } from "reactstrap"
import { dailyTradeStatus, indexCloseStatus, isoToLocale, socketConnectionStatus, tradeFoundStatus, tradeResultStatus, tradeRunningStatus } from "../utils"

export const stockColumnList = [
   // {
   //    name: "ID",
   //    sortable: true,
   //    minWidth: "70px",
   //    maxWidth: "70px",
   //    selector: (row) => row._id
   // },
   {
      name: "INDEX",
      sortable: true,
      minWidth: "120px",
      maxWidth: "120px",
      selector: (row) => row.name
   },
   {
      name: "CLOSE (5-min)",
      sortable: true,
      minWidth: "130px",
      maxWidth: "130px",
      selector: (row) => row.index.lastClose
   },
   {
      name: "SOCKET STATUS",
      sortable: true,
      minWidth: "180px",
      maxWidth: "180px",
      selector: (row) => socketConnectionStatus[row?.isSocketConnected]
   },
   {
      name: "Index Status",
      sortable: true,
      minWidth: "200px",
      maxWidth: "200px",
      selector: (row) => indexCloseStatus[row?.lastIndexCloseStatus]
   },
   {
      name: "LAST TRADE TIME",
      sortable: true,
      minWidth: "220px",
      selector: (row) => row?.lastTradeFoundTime && isoToLocale(row?.lastTradeFoundTime)
   },
   {
      name: "TRADE Running Status",
      sortable: true,
      minWidth: "100px",
      selector: (row) => tradeRunningStatus[row?.isTradeRunning]
   },
   {
      name: "COUNT",
      sortable: true,
      minWidth: "95px",
      maxWidth: "95px",
      selector: (row) => row.dailyTradeCount
   },
   // {
   //    name: "STRIKE PRICE DIFFERENCE",
   //    sortable: true,
   //    minWidth: "150px",
   //    selector: (row) => row.strikeDifference
   // },
   {
      name: "DAILY TRADE STATUS",
      sortable: true,
      maxWidth: "100px",
      selector: (row) => dailyTradeStatus[!row?.isForceStop]
   },

   {
      name: "ACTIVE",
      sortable: true,
      minWidth: "90px",
      maxWidth: "90px",
      selector: (row) => (
         <div className="form-switch form-check-success">
            <Input type="switch" id="" onChange={(e) => console.log(e.target.checked)} name="success" disabled checked={row.isActiveFromAdmin} />
         </div>
      )
   }
]

export const userColumnList = [
   {
      name: "ID",
      sortable: true,
      minwidth: "150px",
      selector: (row) => row._id
   },

   {
      name: "Email",
      sortable: true,
      minwidth: "100px",
      selector: (row) => row.email
   },
   {
      name: "Contact No",
      sortable: true,
      minwidth: "100px",
      selector: (row) => row.mobileNo
   },
   {
      name: "STOCK DETAIL",
      sortable: true,
      minwidth: "150px",
      selector: (row) => (
         <div className="">
            {row.stockDetail?.map((e, index) => {
               return (
                  <div className="d-flex justify-content-between" key={index}>
                     <span>{e.stockName} :</span> <span>{e.quantity}</span>
                  </div>
               )
            })}
         </div>
      )
   },
   {
      name: "Name",
      sortable: true,
      minwidth: "100px",
      selector: (row) => row.name
   },
   {
      name: "ACTIVE",
      sortable: true,
      minwidth: "150px",
      selector: (row) => (
         <div className="form-switch form-check-success">
            <Input type="switch" id="" onChange={(e) => console.log(e.target.checked)} name="success" disabled checked={row.isApprovedFromAdmin} />
         </div>
      )
   }
]
export const unloggedUserColumnList = [
   {
      name: "ID",
      sortable: true,
      minwidth: "150px",
      selector: (row) => row.userId
   },
   {
      name: "Name",
      sortable: true,
      minwidth: "100px",
      selector: (row) => row.name
   },
   // {
   //    name: "Email",
   //    sortable: true,
   //    minwidth: "100px"
   //    // selector: (row) => row.email
   // },
   {
      name: "Contact No",
      sortable: true,
      minwidth: "100px",
      selector: (row) => row.no
   }
   // {
   //    name: "ACTIVE",
   //    sortable: true,
   //    minwidth: "150px",
   //    selector: (row) => (
   //       <div className="form-switch form-check-success">
   //          <Input type="switch" id="" onChange={(e) => console.log(e.target.checked)} name="success" disabled checked={row.isApprovedFromAdmin} />
   //       </div>
   //    )
   // }
]

export const tradeLogColumnList = [
   {
      name: "Trading Symbol",
      sortable: true,
      minWidth: "300px",
      maxWidth: "300px",
      selector: (row) => row.tradingsymbol
   },
   {
      name: "Buy Price",
      sortable: true,
      maxWidth: "120px",
      selector: (row) => row.buyPrice
   },
   {
      name: "Sell Price",
      sortable: true,
      maxWidth: "100px",
      selector: (row) => row.sellPrice
   },
   {
      name: "Net Point",
      sortable: true,
      minwidth: "100px",
      selector: (row) => <div style={{ color: row.netPoint.toFixed(2) > 0 ? "green" : "red", fontWeight: 600 }}>{row.netPoint.toFixed(2)}</div>
   },
   {
      name: "Trade Result",
      sortable: true,
      minWidth: "200px",
      maxWidth: "200px",
      selector: (row) => tradeResultStatus[row.tradeResult]
   },
   {
      name: "Entry Time",
      sortable: true,
      maxWidth: "250px",
      selector: (row) => row.entryTime
   },
   {
      name: "Exit Time",
      sortable: true,
      maxWidth: "250px",
      selector: (row) => row.exitTime
   }
]

export const viewUserPositionColumnList = [
   {
      name: "Trading Symbol",
      sortable: true,
      minwidth: "150px",
      selector: (row) => row.tradingsymbol
   },
   {
      name: "Buy Price",
      sortable: true,
      maxWidth: "100px",
      selector: (row) => row.buyavgprice
   },
   {
      name: "Sell Price",
      sortable: true,
      maxWidth: "100px",
      selector: (row) => row.sellavgprice
   },
   {
      name: "Buy Quantity",
      sortable: true,
      minwidth: "100px",
      selector: (row) => row.buyqty
   },
   {
      name: "Sell Quantity",
      sortable: true,
      maxWidth: "150px",
      selector: (row) => row.sellqty
   },
   {
      name: "Net Quantity",
      sortable: true,
      maxWidth: "150px",
      selector: (row) => row.netqty
   }
]
export const viewUserCFPositionColumnList = [
   {
      name: "Trading Symbol",
      sortable: true,
      minwidth: "150px",
      selector: (row) => row.tradingsymbol
   },
   {
      name: "Buy Price",
      sortable: true,
      maxWidth: "100px",
      selector: (row) => row.cfbuyavgprice
   },
   {
      name: "Sell Price",
      sortable: true,
      maxWidth: "100px",
      selector: (row) => row.cfsellavgprice
   },
   {
      name: "Buy Quantity",
      sortable: true,
      minwidth: "100px",
      selector: (row) => row.cfbuyqty
   },
   {
      name: "Sell Quantity",
      sortable: true,
      maxWidth: "150px",
      selector: (row) => row.cfsellqty
   }
]
export const viewUserLogsColumnList = [
   {
      name: "Order ID",
      sortable: true,
      minwidth: "150px",
      selector: (row) => row.orderId
   },
   {
      name: "Trading Symbol",
      sortable: true,
      maxWidth: "150px",
      selector: (row) => row.tradingsymbol
   },
   {
      name: "Price",
      sortable: true,
      maxWidth: "100px",
      selector: (row) => row.price
   },
   {
      name: "Trade Status",
      sortable: true,
      maxWidth: "120px",
      selector: (row) => row.transactiontype
   },

   {
      name: "quantity",
      sortable: true,
      maxWidth: "100px",
      selector: (row) => row.quantity
   },
   {
      name: "Order Status",
      sortable: true,
      maxWidth: "150px",
      selector: (row) => row.orderStatus
   },
   {
      name: "Date",
      sortable: true,
      minwidth: "150px",
      selector: (row) => isoToLocale(row.time)
   }
]

export const viewUserStockListColumnList = [
   {
      name: "Stock ID",
      sortable: true,
      minwidth: "150px",
      selector: (row) => row.stockId
   },
   {
      name: "Stock Name",
      sortable: true,
      minwidth: "100px",
      selector: (row) => row.stockName
   },
   {
      name: "Quantity",
      sortable: true,
      minwidth: "100px",
      selector: (row) => row.quantity
   },
   {
      name: "Status",
      sortable: true,
      minwidth: "150px",
      selector: (row) => (
         <div className="form-switch form-check-success">
            <Input type="switch" id="" onChange={(e) => console.log(e.target.checked)} name="success" disabled checked={row.isActive} />
         </div>
      )
   }
]
